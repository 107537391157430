
    /*  ----- [DNTS APR 2024] -----
        > Developed by: DarkNext Tech. Software (https://darknext.net)
        > File Desc: ClassicJS Distribution Storage Mechanism Implementation | NODENET (https://nodenet.ru)
            * -> This file must be run with dependencies
        > Dev. Start: 31.03.24
        > Version: 1.0.0.0_BETA
        > Production build was obfuscated
    */

    let TASKS_LIST = [];

    export function getTasks(){
        return TASKS_LIST.reverse();
    }

    function addTask(taskInfo){
        TASKS_LIST.push(taskInfo)
    }

    export function getTaskState(fid){
         return TASKS_LIST.find(({ id }) => Number(id) === Number(fid));
    }

    export function fileSlice(taskInfo, callback){
        let rangeArray = countFragmentsPerPool(taskInfo.file.size, countFragments(taskInfo.file.size, taskInfo.bufferSize), taskInfo.bufferSize);
        let __endRange = uploaderSlicer(taskInfo.file.size, taskInfo.bufferSize);

        let __dsmAddr = 0;
        let __interAddr = 0;

        __endRange.forEach(i => {
            i.forEach(s => {
                __endRange[__dsmAddr][__interAddr] = {
                    'data': new File([taskInfo.file.slice(rangeArray[s].start, rangeArray[s].end)], 'part_dism', {'lastModified': Date.now(), 'type': 'application/octet-stream'}),
                    'inter': {'level': __dsmAddr, 'address': __interAddr}
                };

                __interAddr++;
            });

            __dsmAddr++;
            __interAddr = 0;
        });

        // ======== [UPLOAD DATA] ======== //

        let __uploadedCount = 0;
        let __uploadedInBytes = 0;
        let __uploaderCursor = 0;
        let __uploadsCursorMax = __endRange.length;
        let __levelMaxCount = 0;
        let __levelMaxCountUploaded = 0;
        let __interLevelAddr = 0;

        let __file_instruct = __endRange;

        function __uploadInit(__file){
            __levelMaxCountUploaded = 0;
            __interLevelAddr = 0;
            __levelMaxCount = __endRange[__uploaderCursor].length;

            __endRange[__uploaderCursor].forEach(__interfile => {
                fileSender(__interfile.data).
                then(result => {
                    console.log(result.statusCode)

                    __file_instruct[__uploaderCursor][__interfile.inter.address] = {'msid': result.result.document.file_id, 'inter_addr': __interfile.inter, 'mssize': result.result.document.file_size};

                    __uploadedCount++;
                    __uploadedInBytes += result.result.document.file_size;
                    __interLevelAddr++;
                    __levelMaxCountUploaded++;

                    getTaskState(taskInfo.fileID).fragmentsUploaded++;
                    getTaskState(taskInfo.fileID).fragmentsUploadedInBytes += result.result.document.file_size;
                    getTaskState(taskInfo.fileID).percentage = Number((__uploadedCount / countFragments(taskInfo.file.size, taskInfo.bufferSize)) * 100).toFixed(0);

                    console.log(getTasks());

                    if (__levelMaxCount === __levelMaxCountUploaded){
                        if ((__uploaderCursor+1) < __uploadsCursorMax){
                            __uploaderCursor++;

                            callback({
                                'state': 1,
                                'fileinfo': {
                                    'uploaded': __uploadedCount,
                                    'upload_max': countFragments(taskInfo.file.size, taskInfo.bufferSize),
                                    'size_format': formatBytes(taskInfo.file.size),
                                },
                                'percentage': Number((__uploadedCount / countFragments(taskInfo.file.size, taskInfo.bufferSize)) * 100).toFixed(0),
                                'is_ready': false
                            });

                            __uploadInit(__file);
                        } else {
                            let __instructionFull = __instructReformat(
                                {
                                    'filesize': __file.size,
                                    'filename': __file.name,
                                    'fileext': __file.name.split('.').at(-1),
                                    'bufferSize': taskInfo.bufferSize,
                                }, __file_instruct);

                            getTaskState(taskInfo.fileID).state = 2;

                            callback({
                                'state': 2,
                                'fileinfo': {
                                    'uploaded': __uploadedCount,
                                    'upload_max': countFragments(taskInfo.file.size, taskInfo.bufferSize),
                                    'size_format': formatBytes(taskInfo.file.size),
                                },
                                'is_ready': true,
                                'percentage': Number((__uploadedCount / countFragments(taskInfo.file.size, taskInfo.bufferSize)) * 100).toFixed(0),
                                'result': __instructionFull
                            });
                        }
                    }
                });
            });
        }

        __uploadInit(taskInfo.file);

        callback({
            'state': 1,
            'fileinfo': {
                'uploaded': __uploadedCount,
                'upload_max': countFragments(taskInfo.file.size, taskInfo.bufferSize),
                'size_format': formatBytes(taskInfo.file.size),
            },
            'percentage': Number((__uploadedCount / countFragments(taskInfo.file.size, taskInfo.bufferSize)) * 100).toFixed(0),
            'is_ready': false
        });

        addTask({
            'id': Number(taskInfo.fileID),
            'direction': 0,
            'filename': taskInfo.file.name,
            'fileext': taskInfo.file.name.split('.').at(-1),
            'filesize': taskInfo.file.size,
            'fragmentsCount': countFragments(taskInfo.file.size, taskInfo.bufferSize),
            'fragmentsUploaded': 0,
            'fragmentsUploadedInBytes': 0,
            'status': 1,
            'percentage': 0
        });
    }

    function __instructReformat(fileinfo, instruct){
        let __normalInst = {
            'origin': {
                'name': fileinfo.filename,
                'type': fileinfo.fileext,
                'size': fileinfo.filesize,
            },
            'instruction': {
                'id': 0,
                'ver': 1.0,
                'date_create': Date.now(),
                'bufferSize': fileinfo.bufferSize,
                'creator': 'NODENET-WEB'
            },
            'fragments': []
        };

        instruct.forEach(l => {
            l.forEach(e => {
                __normalInst.fragments.push({'id': e.msid, 'size': e.mssize});
            });
        });

        console.log(__normalInst);

        return __normalInst;

        //downloadFile(__normalInst);
    }

    function uploaderSlicer(filesize, bufferSize, threadsCount = 4){
        let rangeArray = countFragmentsPerPool(filesize, countFragments(filesize, bufferSize), bufferSize);

        let separateCount = Number(Math.ceil(rangeArray.length / threadsCount).toFixed(0));

        let rangePointer = 0;
        let __depthIter = 0;
        let rebuildRangesArray = new Array(separateCount).fill([]);

        let __endRange = [];

        rebuildRangesArray.forEach(r => {
            if ((rangePointer + threadsCount) < rangeArray.length){
                let __indentRange = createRange(rangePointer, rangePointer + threadsCount, false);

                __endRange.push(__indentRange);

                rangePointer += ((rangePointer + threadsCount) < rangeArray.length) ? threadsCount : (rangeArray.length - rangePointer);
            } else {
                let __indentRange = createRange(rangePointer, rangeArray.length, false);

                __endRange.push(__indentRange);

            }

            __depthIter++;
        });

        return __endRange;
    }

    export function downloadFile(fileid, instruction, callback){
        let __instructionDwn = instruction.fragments;

        let __max = instruction.fragments.length;
        let separateCount = Number(Math.ceil(__max / 4).toFixed(0));

        let rangePointer = 0;
        let __depthIter = 0;

        // ==== [BUILD QUERY]

        let __instructQueue = uploaderSlicer(instruction.origin.size, instruction.instruction.bufferSize);

        __instructQueue.forEach(e => {
            rangePointer = 0;

            e.forEach(s => {
                __instructQueue[__depthIter][rangePointer] = instruction.fragments[s];

                rangePointer++;
            });

            __depthIter++;
        });

        // ==== [Start download] ====

        let __downloadCount = 0;
        let __downloaderCursor = 0;
        let __downloadsCursorMax = __max;
        let __levelMaxCount = 0;
        let __levelMaxCountDownloaded = 0;
        let __interLevelAddr = 0;

        let __fullFileBlob = new Array(__max);

        let proxyList = [
            'https://darknext.net/nodenet-proxy/',
            'https://network.nodenet.ru/webdrive/proxy/',
            'https://rk17386.bomj.one/web-proxy/'
        ];

        function __downloadInit(){
            __levelMaxCountDownloaded = 0;
            __interLevelAddr = 0;
            __levelMaxCount = __instructQueue[__downloaderCursor].length;

            __instructQueue[__downloaderCursor].forEach(__interaddr => {

                fileGetter(__interaddr.id)
                    .then(res => res.json())
                    .then(blob => {
                        let fds = new FormData();
                        fds.append('address', 'https://api.telegram.org/file/bot5695758233:AAGi61Xczt5wTtpSx647gQlcsh1RL37PnNA/' + blob.result.file_path);

                        fetch('https://api.telegram.org/file/bot5695758233:AAGi61Xczt5wTtpSx647gQlcsh1RL37PnNA/' + blob.result.file_path, {
                        //fetch(proxyList[getRandomInt(0, proxyList.length-1)], {
                        //fetch(proxyList[0], {
                            'method': 'POST',
                            'body': fds,
                            'dataType': 'binary',
                            'timeout': 600,
                        }).then(res => res.blob())
                            .then(blob => {
                                __instructionDwn.forEach(es => {
                                    if (es.id === __interaddr.id){
                                        es.addr = blob;
                                    }
                                });

                                __downloadCount++;
                                __interLevelAddr++;
                                __levelMaxCountDownloaded++;

                                getTaskState(fileid).fragmentsUploaded++;
                                getTaskState(fileid).fragmentsUploadedInBytes += blob.size;
                                getTaskState(fileid).percentage = Number((__downloadCount / __max) * 100).toFixed(0);

                                if (__downloadCount === __downloadsCursorMax){
                                    createFile(instruction.origin.name, __instructionDwn);

                                    callback({
                                        'state': 2,
                                        'fileinfo': {
                                            'uploaded': __downloadCount,
                                            'upload_max': countFragments(instruction.origin.size, instruction.instruction.bufferSize),
                                            'size_format': formatBytes(instruction.origin.size),
                                        },
                                        'percentage': Number((__downloadCount / __max) * 100).toFixed(0),
                                        'is_ready': false
                                    });

                                    getTaskState(fileid).state = 2;
                                } else {
                                    if (__levelMaxCountDownloaded === __levelMaxCount){
                                        __downloadInit();
                                    }

                                    callback({
                                        'state': 1,
                                        'fileinfo': {
                                            'uploaded': __downloadCount,
                                            'upload_max': countFragments(instruction.origin.size, instruction.instruction.bufferSize),
                                            'size_format': formatBytes(instruction.origin.size),
                                        },
                                        'percentage': Number((__downloadCount / __max) * 100).toFixed(0),
                                        'is_ready': false
                                    });
                                }
                            })
                    });
            });

            __downloaderCursor++;
        }

        addTask({
            'id': Number(fileid),
            'direction': 1,
            'filename': instruction.origin.name,
            'fileext': instruction.origin.name.split('.').at(-1),
            'filesize': instruction.origin.size,
            'fragmentsCount': countFragments(instruction.origin.size, instruction.instruction.bufferSize),
            'fragmentsUploaded': 0,
            'fragmentsUploadedInBytes': 0,
            'status': 1,
            'percentage': 0
        });

        __downloadInit();

        callback({
            'state': 1,
            'fileinfo': {
                'uploaded': __downloadCount,
                'upload_max': countFragments(instruction.origin.size, instruction.instruction.bufferSize),
                'size_format': formatBytes(instruction.origin.size),
            },
            'percentage': Number((__downloadCount / __max) * 100).toFixed(0),
            'is_ready': false
        });
    }

    function createFile(filename, instruction){
        let blob = [];

        instruction.forEach(e => {
            blob.push(e.addr);
        });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob(blob));
        link.download = filename;
        link.click();
    }

    function fileSender(__file){
        let __fbody = new FormData();
        __fbody.append('chat_id', 1183074922);
        __fbody.append('document', __file);

        return sendFragment({
            'url': 'https://api.telegram.org/bot5695758233:AAGi61Xczt5wTtpSx647gQlcsh1RL37PnNA/sendDocument',
            'clientParams': {
                'method': 'post',
                'body': __fbody,
            }
        });
    }

    function fileGetter(__address){
        let fd = new FormData();
        fd.append('file_id', __address);

        return fetch('https://api.telegram.org/bot5695758233:AAGi61Xczt5wTtpSx647gQlcsh1RL37PnNA/getFile', {
            'method': 'POST',
            'body': fd,
            'dataType': 'binary'
        })
    }

    function countFragmentsPerPool(filesize, fragments, bufferSize){
        let __maxsize = filesize;
        let __sizesPool = [];
        let __cursorStart = 0;
        let __cursorEnd = (filesize > bufferSize) ? bufferSize : filesize;

        for(let i = 0; i < fragments; i++){
            if (__maxsize >= bufferSize){
                __sizesPool.push({'size': bufferSize, 'start': __cursorStart, 'end': __cursorEnd, 'address': i});
                __maxsize -= bufferSize;

                __cursorStart += bufferSize;
                __cursorEnd = (filesize - __cursorStart >= bufferSize) ? __cursorStart + bufferSize : filesize;
            } else {
                __sizesPool.push({'size': __maxsize, 'start': __cursorStart, 'end': __cursorEnd, 'address': i});
                __maxsize -= __maxsize;
            }
        }

        let __total = 0;

        __sizesPool.forEach(e => {
            __total += e.size;
        })

        return __sizesPool;
    }

    function createRange(start, end, include = true){
        let numbers = [];

        if (include){
            for (let i = start; i <= end; i++) {
                numbers.push(i)
            }
        } else {
            for (let i = start; i < end; i++) {
                numbers.push(i)
            }
        }

        return numbers;
    }

    function countFragments(filesize, bufferSize){
        return Number(Math.ceil(filesize / bufferSize).toFixed(0));
    }

    function sendFragment(dataset){
        return new Promise((result, reject) => {
            fetch(dataset.url, dataset.clientParams).
            then(function (response) {
                    result(response.json())
                }
            ).then(data => reject(data))
        });
    }

    function getRandomInt(min, max) {
        return Number(Math.random() * (max - min) + min).toFixed(0);
    }

    export function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Байт'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПТ', 'ЭБ', 'ЗБ', 'ЭБ']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }


import React from 'react';
import { Link } from "react-router-dom";
import * as ui from "../../service/ui";
import IdentifyValidate from "../Identify-validate";
import { render } from 'react-dom';
import {getVal} from "../../service/ui";
import * as http from "../../service/httpClient";

const domNode = document.getElementById('root');

const Index = () => {
    ui.setTitle( ui.PROD + ' - Создать аккаунт');

    return (
        <>
            <div className='main-sm'>
                <div className='semi-left'>
                    <div className='brand gap-5'>
                        <h3>DARKID</h3>
                        <h5>DARK/NEXT/
                            <fs>SOFTWARE</fs>
                        </h5>
                    </div>
                </div>
                <div className='semi-right fade-in-right'>
                    <div className='info-pan gap-10'>
                        <h1>Создать аккаунт</h1>
                        <h5>Уже есть аккаунт?<br/>
                            <Link to={'/identify'}>войти в аккаунт</Link></h5>
                    </div>
                    <div className='fields gap-10'>
                        <input autoFocus='true' className='field' placeholder="Имя пользователя" type="text" id = 'username' onKeyUp={(event) => {nextField(event, ui.gid('nickname'))}}/>
                        <input className='field' placeholder="Никнейм" type="text" id = 'nickname' onKeyUp={(event) => {nextField(event, ui.gid('email'))}}/>
                        <input className='field' placeholder="EMAIL" type="email" id = 'email' onKeyUp={(event) => {nextField(event, ui.gid('pass'))}}/>
                        <input className='field' placeholder="Пароль" type='password' id = 'pass' onKeyUp={(event) => {nextField(event, null, true)}}/>
                    </div>
                    <button id = 'regBTN' className='btn main-btn' onClick={() => {reg()}}>Продолжить</button>
                </div>
            </div>
        </>
    );
};

function nextField(event, name, isLast = false){
    if (event.keyCode === 13) {
        if (isLast) {
            reg();
        } else {
            if (name !== null) {
                name.focus();
            }
        }
    }
}

function reg() {
    const data = new URLSearchParams();
    data.append('act', 'reg');
    data.append('username', getVal('username'));
    data.append('nickname', getVal('nickname'));
    data.append('email', getVal('email'));
    data.append('pass', getVal('pass'));

    ui.gid('regBTN').textContent = 'Проверка...';
    ui.gid('regBTN').classList.add('btn-disabled');

    http.send({
        "method": 'post',
        "body": data
    },  async function (res) {
        if (res.code === 200){
            renderValidate(res.content.mail_provider);

            localStorage.setItem('user', res.content.token);
        } else {
            ui.gid('regBTN').textContent = 'Продолжить';
            ui.gid('regBTN').classList.remove('btn-disabled');
        }
    })
}

function renderValidate(provider){
    ui.setTitle( ui.PROD + ' - Подтверждение аккаунта');

    render(<IdentifyValidate dataset={provider}/>, domNode);
}

export default Index;
import React from 'react';
import * as ui from "../../service/ui";
import * as user from '../../service/user';
import Avatar from "../../Pages/User-layout/Form-components/avatar";

const Index = () => {
    let u = user.getUserData();

    ui.setTitle(ui.PROD + ' - Профиль ' + u.username + '(@' + u.nickname + ')');

    return (
        <>
            <div className='scene-wrap gap-30 fade-in-bottom'>
                <h1 className='base-heading'>{u.username + ' (@' + u.nickname + ')'}</h1>
                <div className='profile-content gap-25'>
                    {<Avatar type='full'/>}
                    <div className='prof-data com-col-left gap-5'>
                        <h3>{u.username}</h3>
                        <h5>{u.email}</h5>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
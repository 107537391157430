
    const BASE_API_URL = 'https://api.nodenet.ru/web-drive/';
    export function send(paramsLIST){
        return new Promise((result, reject) => {
            fetch(BASE_API, paramsLIST)
                .then(function (response) {
                    result(response.json())
                })
                .then(data => reject(data));
        });

    }

    export const BASE_API = BASE_API_URL;
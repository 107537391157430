import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import { render } from 'react-dom';
import * as ui from "../../service/ui";
import {promiseActivateAccount} from "../../service/user";


const domNode = document.getElementById('root');
const Index = () => {
    ui.setTitle(ui.PROD + ' - Подтверждение аккаунта...');

    let code = useParams().code;

    useEffect(() => {
        promiseActivateAccount(code).then(
            result => {
                window.location.replace('/u/main');
            },
            error => {
                ui.setTitle(ui.PROD + ' - Сбой подтверждения');

                render(<div className='com-col-center gap-5'><h3 className='err-code'>{error.code}</h3><h1 className = 'white-txt'>{error.content}</h1></div>, domNode);
            }
        )
    }, [code]);


    return (
        <>
            <h1 className = 'white-txt'>Авторизуемся...</h1>
        </>
    );
};

export default Index;
import React from 'react';
import FullSizeFile from "./fullSizeFile";

const FilesRow = ({content, type}) => {
    return (
        <>
            <div className={'files-row ' + type}>
                {content.map((item) => <FullSizeFile fileinfo={item}></FullSizeFile> )}
            </div>
        </>
    );
};

export default FilesRow;

import React from 'react';
import * as ui from "../../service/ui";

const Index = () => {
    ui.setTitle(ui.PROD + ' - Настройки');

    return (
        <>
            <div className='scene-wrap gap-30 fade-in-bottom' id = 'SETT'>
                <h1 className='base-heading' id='SETT_TEXT_H'>Настройки</h1>
            </div>
        </>
    );
};

export default Index;
import React, {useEffect, useState} from 'react';
import * as ui from "../../service/ui";
import * as user from "../../service/user";
import FullSizeFile from "./Form-components/fullSizeFile";
import * as NN from "../../service/NODENET-JS/nodenet";
import * as https from "../../service/httpClient";
import {gid} from "../../service/ui";
import FilesRow from "./Form-components/filesRow";

const Index = () => {
    ui.setTitle(ui.PROD + ' - Файлы');

    const defaultValue = [];
    const [files, setData] = useState(defaultValue);

    let __filesList = [];

    useEffect(() => {
        let body = new FormData();
        body.append('act', 'get-filelist');
        body.append('storage_id', 1);

        https.send(
            {
                'method': 'POST',
                'body': body
            }
        ).
        then(res => {
            if (res.code === 200){
                setData(res.content);
            }
        })


    }, [setData]);

    let __counter = 0;

    function __destFiles(){
        if ((files.length - __counter) >= 4){
            let range = createRange(__counter, __counter + 4, false);
            let _cards = [];

            range.forEach(i => {
                _cards.push(files[i]);
            });

            __filesList.push(<FilesRow content={_cards} type = 'row-justify'></FilesRow>);

            __counter += 4;

            if (__counter < files.length){
                __destFiles()
            }
        } else {
            let range = createRange(__counter, __counter + (files.length - __counter), false);
            let _cards = [];

            range.forEach(i => {
                _cards.push(files[i]);
            });

            __filesList.push(<FilesRow content={_cards} type = 'row-start'></FilesRow>);

            __counter += (files.length - __counter);
        }
    }

    __destFiles();



    return (
        <>
            <div className='scene-wrap gap-30 fade-in-bottom'>
                <h1 className='base-heading'>Все файлы</h1>
                <div className='all-files-bar gap-15'>
                    <div className='dsm-wrap gap-15'>
                        <button className='btn main-btn' onClick={() => {
                            fileUploader()
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                      d="M12 20v-8m0 0V4m0 8h8m-8 0H4"/>
                            </svg>
                            Загрузить
                        </button>
                        <div className='filter-type type-file'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5">
                                    <path stroke-linecap="round"
                                          d="M4 4v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8.342a2 2 0 0 0-.602-1.43l-4.44-4.342A2 2 0 0 0 13.56 2H6a2 2 0 0 0-2 2m5 9h6m-6 4h3"/>
                                    <path d="M14 2v4a2 2 0 0 0 2 2h4"/>
                                </g>
                            </svg>
                            <h3>Файлы</h3>
                        </div>
                        <div className='filter-type type-image'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                   stroke-width="1.5">
                                    <path d="M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4z"/>
                                    <circle cx="8.5" cy="8.5" r="2.5"/>
                                    <path
                                        d="M14.526 12.621L6 22h12.133A3.867 3.867 0 0 0 22 18.133V18c0-.466-.175-.645-.49-.99l-4.03-4.395a2 2 0 0 0-2.954.006"/>
                                </g>
                            </svg>
                            <h3>Изображения</h3>
                        </div>
                        <div className='filter-type type-video'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                   stroke-width="1.5">
                                    <rect width="20" height="16" x="2" y="4" rx="4"/>
                                    <path d="m15 12l-5-3v6z"/>
                                </g>
                            </svg>
                            <h3>Видео</h3>
                        </div>
                        <div className='filter-type type-audio'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <g fill="none" stroke="currentColor" stroke-width="1.5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v14"/>
                                    <path
                                        d="M19 7.674v-.657a4 4 0 0 0-2.901-3.846l-2.824-.807A1 1 0 0 0 12 3.326V7l5.725 1.636A1 1 0 0 0 19 7.674Z"/>
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M12 18a3 3 0 1 1-6 0c0-1.657 1.343-2 3-2s3 .343 3 2"/>
                                </g>
                            </svg>
                            <h3>Аудио</h3>
                        </div>
                        <input className='search-edit' placeholder='Поиск файлов' type='text'/>
                    </div>
                </div>
                <div className='files-list gap-20'>
                    <h1 className='files-group'>Сегодня <b className='file-counter'>12</b></h1>
                    {
                        __filesList.map((item) => item)
                    }
                </div>
            </div>
        </>
    );


};

export default Index;

/*
<div className='files-row row-justify'>
                        <FullSizeFile/>
                        <FullSizeFile/>
                        <FullSizeFile/>
                        <FullSizeFile/>
                    </div>
                    <div className='files-row row-justify'>
                        <FullSizeFile/>
                        <FullSizeFile/>
                        <FullSizeFile/>
                        <FullSizeFile/>
                    </div>
                    <div className='files-row row-justify'>
                        <FullSizeFile/>
                        <FullSizeFile/>
                        <FullSizeFile/>
                        <FullSizeFile/>
                    </div>
                    <div className='files-row row-start'>
                        <FullSizeFile/>
                        <FullSizeFile/>
                    </div>
                    <h1 className='files-group'>20 марта <b className='file-counter'>198</b></h1>
                    <div className='files-row row-start'>
                        <FullSizeFile/>
                        <FullSizeFile/>
                    </div>


*/

function fileUploader(){
    let a = document.createElement('input');
    a.type = 'file';
    a.multiple = true;

    a.click();

    a.onchange = () => {
        console.log(a.files);

        let body = new FormData();
        body.append('act', 'file-upload');
        body.append('filename', a.files[0].name);

        https.send(
            {
                'method': 'POST',
                'body': body
            }
        ).
        then(res => {
            if (res.code === 200){
                let __fileID = Number(res.content.file_id);

                NN.fileSlice(
                    {
                        'file': a.files[0],
                        'bufferSize': 20971520,
                        'threadsCount': 4,
                        'fileID': Number(__fileID),
                    }
                    , function (state){

                        if (state.state === 2 && state.is_ready === true){
                            let body = new FormData();
                            body.append('act', 'update-file-upload');
                            body.append('fileid', __fileID);
                            body.append('instruct', JSON.stringify(state.result));

                            https.send(
                                {
                                    'method': 'POST',
                                    'body': body
                                }
                            ).
                            then(res => {
                                console.log(res)
                            });
                        } else if (state.state === 1){
                            ui.gid('IO_COUNTER').style.display = 'block';
                        }

                        console.log(state)
                    });
            }
        });
    }
}

function createRange(start, end, include = true){
    let numbers = [];

    if (include){
        for (let i = start; i <= end; i++) {
            numbers.push(i)
        }
    } else {
        for (let i = start; i < end; i++) {
            numbers.push(i)
        }
    }

    return numbers;
}
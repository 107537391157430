import React from 'react';
import * as ui from "../../service/ui";
import * as NN from "../../service/NODENET-JS/nodenet";
import Task from "./Form-components/Task";

const Index = () => {
    ui.setTitle(ui.PROD + ' - Операции ввода/вывода');

    let tasks = NN.getTasks();

    return (
        <>
            <div className='scene-wrap gap-30 fade-in-bottom'>
                <div className='header-wrap gap-10'>
                    <h1 className='base-heading'>Все операции</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" d="m6 10l6 6l6-6"/>
                    </svg>
                </div>
                <div className='tasksblock gap-20'>
                    {
                        tasks.map((item) =>
                            <Task taskinfo={item}></Task>
                        )
                    }
                </div>
                <div className='header-wrap gap-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" d="M6 3v18M6 3l4 4M6 3L2 7m16 14V3m0 18l4-4m-4 4l-4-4"/>
                    </svg>
                    <p className='base-desc'>Загрузка: в 4 потока<br/>Скачивание: в 4 потока</p>
                </div>
            </div>
        </>
    );
};

export default Index;
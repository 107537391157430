import React from 'react';
import * as user from '../../service/user';
import * as ui from "../../service/ui";
import Wallet from "./Form-components/wallet";
import LitefileCard from "../User-services/Form-components/litefileCard";

const Index = () => {
    ui.setTitle(ui.PROD + ' - Главная');

    let u = user.getUserData();

    return (
        <>
            <div className='scene-wrap gap-30 fade-in-bottom'>
                <h1 className='base-heading'>{ui.getDayPart()}, <b>{u.username}</b>!</h1>
                <div className='cards-list gap-10'>
                    <div className= 'info-card gap-5'>
                        <h4>Стандартное хранилище</h4>
                        <h1>11,3 МБ из 5 ГБ использовано</h1>
                    </div>
                </div>
                <h4 className='add-text'>Последние добавленные</h4>
                <div className='service-list gap-10'>
                    <div className='files-table'>
                        <h3>Название</h3>
                        <h3>Размер</h3>
                        <h3>Дата создания</h3>
                    </div>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                    <LitefileCard/>
                </div>
            </div>
        </>
    );
};

export default Index;
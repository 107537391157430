import React from 'react';
import * as ui from "../../service/ui";
import {Link} from "react-router-dom";

const Index = () => {
    ui.setTitle(ui.PROD + ' - Хранилища');

    return (
        <>
            <div className='scene-wrap gap-30 fade-in-bottom'>
                <h1 className='base-heading'>Хранилища</h1>
                <div className='com-bar gap-15'>
                    <div className='dsm-wrap gap-15'>
                        <Link to = '/d/storages/create'>
                            <button className='btn main-btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                          d="M12 20v-8m0 0V4m0 8h8m-8 0H4"/>
                                </svg>
                                Создать
                            </button>
                        </Link>
                        <button className='btn btn-disabled'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2">
                                    <path stroke-linejoin="round" d="M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v5H2z"/>
                                    <path d="M18.5 16.5h-3"/>
                                    <path stroke-linejoin="round"
                                          d="M12 11h10v7a4 4 0 0 1-4 4h-6zm0 0H2v7a4 4 0 0 0 4 4h6z"/>
                                    <path d="M5.5 18L7 16.5m0 0L8.5 15M7 16.5L8.5 18M7 16.5L5.5 15"/>
                                </g>
                            </svg>
                            Калькулятор
                        </button>
                    </div>
                </div>
                <div className='storages-list'>
                    <div className='storage-card'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5">
                                <path stroke-linecap="round"
                                      d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.749l-7 3.888a2 2 0 0 1-1.942 0l-7-3.889A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874z"/>
                                <path stroke-linecap="round" d="m7.5 4.5l9 5V13M6 12.328L9 14"/>
                                <path d="m3 7l9 5m0 0l9-5m-9 5v9.5"/>
                            </g>
                        </svg>
                        <div className='storage-info gap-5'>
                            <h1>Базовый бакет</h1>
                            <h3>Идентификатор: 123-123-123</h3>
                            <h3>Состояние: <b className='state-ok'>В сети</b></h3>
                        </div>
                    </div>
                    <div className='storage-card'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5">
                                <path stroke-linecap="round"
                                      d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.749l-7 3.888a2 2 0 0 1-1.942 0l-7-3.889A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874z"/>
                                <path stroke-linecap="round" d="m7.5 4.5l9 5V13M6 12.328L9 14"/>
                                <path d="m3 7l9 5m0 0l9-5m-9 5v9.5"/>
                            </g>
                        </svg>
                        <div className='storage-info gap-5'>
                            <h1>Базовый бакет</h1>
                            <h3>Идентификатор: 123-123-123</h3>
                            <h3>Состояние: <b className='state-ok'>В сети</b></h3>
                        </div>
                    </div>
                    <div className='storage-card'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5">
                                <path stroke-linecap="round"
                                      d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.749l-7 3.888a2 2 0 0 1-1.942 0l-7-3.889A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874z"/>
                                <path stroke-linecap="round" d="m7.5 4.5l9 5V13M6 12.328L9 14"/>
                                <path d="m3 7l9 5m0 0l9-5m-9 5v9.5"/>
                            </g>
                        </svg>
                        <div className='storage-info gap-5'>
                            <h1>Базовый бакет</h1>
                            <h3>Идентификатор: 123-123-123</h3>
                            <h3>Состояние: <b className='state-ok'>В сети</b></h3>
                        </div>
                    </div>
                    <div className='storage-card'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5">
                                <path stroke-linecap="round"
                                      d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.749l-7 3.888a2 2 0 0 1-1.942 0l-7-3.889A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874z"/>
                                <path stroke-linecap="round" d="m7.5 4.5l9 5V13M6 12.328L9 14"/>
                                <path d="m3 7l9 5m0 0l9-5m-9 5v9.5"/>
                            </g>
                        </svg>
                        <div className='storage-info gap-5'>
                            <h1>Базовый бакет</h1>
                            <h3>Идентификатор: 123-123-123</h3>
                            <h3>Состояние: <b className='state-ok'>В сети</b></h3>
                        </div>
                    </div>
                    <div className='storage-card'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5">
                                <path stroke-linecap="round"
                                      d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.749l-7 3.888a2 2 0 0 1-1.942 0l-7-3.889A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874z"/>
                                <path stroke-linecap="round" d="m7.5 4.5l9 5V13M6 12.328L9 14"/>
                                <path d="m3 7l9 5m0 0l9-5m-9 5v9.5"/>
                            </g>
                        </svg>
                        <div className='storage-info gap-5'>
                            <h1>Базовый бакет</h1>
                            <h3>Идентификатор: 123-123-123</h3>
                            <h3>Состояние: <b className='state-ok'>В сети</b></h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Identity from "./Pages/Identity";
import IdentifyCreate from "./Pages/Identify-create";
import IdentifyRecovery from "./Pages/Identify-recovery";
import UserLayout from "./Pages/User-layout";
import IdentifyCodeValidate from "./Pages/Identify-code-validate";
import * as user from "./service/user";

const root = ReactDOM.createRoot(document.getElementById('root'));
let userIsAuth = true;

/*user.promiseUserCheck.then(
    result => {
        //if (result.code === 200) userIsAuth = !userIsAuth;

        if (userIsAuth) {
            root.render(
                <>
                    <BrowserRouter>
                        <Routes>
                            <Route path="*" element={<Navigate to = '/d/main'/>}/>

                            <Route exact path="/d/main"
                                   element={(userIsAuth) ? <UserLayout page='main'/> : <Identity/>}/>
                            <Route exact path="/d/settings"
                                   element={(userIsAuth) ? <UserLayout page='settings'/> : <Identity/>}/>
                            <Route exact path="/d/profile"
                                   element={(userIsAuth) ? <UserLayout page='profile'/> : <Identity/>}/>
                            <Route exact path="/d/files"
                                   element={(userIsAuth) ? <UserLayout page='files'/> : <Identity/>}/>
                            <Route exact path="/d/storages"
                                   element={(userIsAuth) ? <UserLayout page='storages'/> : <Identity/>}/>
                            <Route exact path="/d/storages/create"
                                   element={(userIsAuth) ? <UserLayout page='storages-create'/> : <Identity/>}/>
                            <Route exact path="/d/io-operations"
                                   element={(userIsAuth) ? <UserLayout page='io'/> : <Identity/>}/>

                            <Route path="/validate/:code" element={<IdentifyCodeValidate/>}/>
                            <Route path="/u/wallet/:wallet"
                                   element={(userIsAuth) ? <UserLayout page='wallet'/> : <Identity/>}/>
                            <Route path="/u/wallet/:wallet/replenish"
                                   element={(userIsAuth) ? <UserLayout page='wallet-replenish'/> : <Identity/>}/>


                        </Routes>
                    </BrowserRouter>
                    <App/>
                </>
            );
        } else {
            root.render(
                <>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/identify" element={<Identity/>}/>
                            <Route path="/identify-create" element={<IdentifyCreate/>}/>
                            <Route path="/identify-recovery" element={<IdentifyRecovery/>}/>
                        </Routes>
                    </BrowserRouter>
                    <App/>
                </>
            );

        }
    },
    error => {

    }
);*/

root.render(
    <>
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Navigate to = '/d/main'/>}/>

                <Route exact path="/d/main"
                       element={(userIsAuth) ? <UserLayout page='main'/> : <Identity/>}/>
                <Route exact path="/d/settings"
                       element={(userIsAuth) ? <UserLayout page='settings'/> : <Identity/>}/>
                <Route exact path="/d/profile"
                       element={(userIsAuth) ? <UserLayout page='profile'/> : <Identity/>}/>
                <Route exact path="/d/files"
                       element={(userIsAuth) ? <UserLayout page='files'/> : <Identity/>}/>
                <Route exact path="/d/storages"
                       element={(userIsAuth) ? <UserLayout page='storages'/> : <Identity/>}/>
                <Route exact path="/d/storages/create"
                       element={(userIsAuth) ? <UserLayout page='storages-create'/> : <Identity/>}/>
                <Route exact path="/d/io-operations"
                       element={(userIsAuth) ? <UserLayout page='io'/> : <Identity/>}/>

                <Route path="/validate/:code" element={<IdentifyCodeValidate/>}/>
                <Route path="/u/wallet/:wallet"
                       element={(userIsAuth) ? <UserLayout page='wallet'/> : <Identity/>}/>
                <Route path="/u/wallet/:wallet/replenish"
                       element={(userIsAuth) ? <UserLayout page='wallet-replenish'/> : <Identity/>}/>


            </Routes>
        </BrowserRouter>
        <App/>
    </>
);




import React from 'react';
import * as ui from "../../service/ui";

const GB_COAST = 1.25;

const Index = () => {
    ui.setTitle(ui.PROD + ' - Создать хранилище');

    return (
        <>
            <div className='scene-wrap gap-30 fade-in-bottom'>
                <h1 className='base-heading'>Создать хранилище</h1>
                <div className='calc'>
                    <div className='selector'>
                        <h5>Тип хранилища</h5>
                        <div className='selector-div'>
                            <div className='var-sl sl-sel'>Объектное хранилище</div>
                            <div className='var-sl'>Личное хранилище</div>
                        </div>
                    </div>
                    <div className='selector'>
                        <h5>Объём дискового пространства</h5>
                        <input id = 'gb_count' onInput={() => {countCoast()}} type='text' className='sel-edit' placeholder='Укажите размер в ГБ'/>
                    </div>
                    <h4 id = 'coast_count' className='coast-c'></h4>
                    <button className='btn main-btn'>Создать</button>
                </div>
            </div>
        </>
    );
};

function countCoast(){
    console.log(ui.gid('coast_count').value);

    ui.gid('coast_count').textContent = 'Расчётная стоимость в месяц: ' + ui.gid('gb_count').value * GB_COAST + ' ₽';
}

export default Index;
import React from 'react';

const litecard = () => {
    return (
        <>
            <div className='file-lite-card'>
                <div className='dism gap-15'>
                    <img src='https://id.darknext.net/storage/users-avatars/RAASKQYRR7EEDSDZ86GTHDZK.jpg'
                         className='lite-prev'/>
                    <div className='fname gap-5'>Avatar<h6 className='img-type-det'>PNG</h6></div>
                </div>
                <h4 className='fdesc'>321 КБ</h4>
                <h4 className='fdesc'>27 марта 2023</h4>
            </div>
        </>
    );
};

export default litecard;

import React from 'react';
import {updateUserAvatar, user} from '../../../service/user';

const Avatar = ({type}) => {
    return (
        <></>
    );

    let content;

    if (user.avatar === null) {
        content = (type === 'min') ? <p className='user-no-ava'>{user.username.slice(0, 1).toUpperCase()}</p> : <p className='user-no-ava profile-ava-full'>{user.username.slice(0, 1).toUpperCase()}</p>;
    } else {
        content = (type === 'min') ? <img src={user.avatar} alt={user.username.slice(0, 1).toUpperCase()}/> : <img className='profile-ava-full' src={user.avatar} alt={user.username.slice(0, 1).toUpperCase()}/>;
    }

    if (type === 'min') {
        return (
            <>
                <div className='uava'>{content}</div>
            </>
        );
    } else {
        return (
            <div className='prof-ava-wrap' onClick={() => {imageSelect()}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                       stroke-width="1.5">
                        <path
                            d="m6.233 5.834l.445-2.226A2 2 0 0 1 8.64 2h6.72a2 2 0 0 1 1.962 1.608l.445 2.226a1.879 1.879 0 0 0 1.387 1.454A3.758 3.758 0 0 1 22 10.934V18a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-7.066a3.758 3.758 0 0 1 2.846-3.646a1.879 1.879 0 0 0 1.387-1.454"/>
                        <circle cx="12" cy="14" r="4"/>
                        <path d="M11 6h2"/>
                    </g>
                </svg>
                {content}
            </div>
        );
    }
};

function imageSelect(){
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png, image/jpeg';
    input.click();

    input.onchange = (event) => {
        updateUserAvatar(input.files[0]).then(
            res => {
                if (res.code === 200){}
            },
            err => {

            }
        )
    }
}

export default Avatar;
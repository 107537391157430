
    const PROD_NAME = 'NODENET';
    const $ = document;

    // ======= EXPORT'S BLOCK =======
    export const PROD = PROD_NAME;
    export const $$ = $;
    export function setTitle(title){
        document.title = title;
    }

    export function getFormattedDate(unix, withHours = true) {
        const date = new Date(unix*1000);

        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let min = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;

        let str;

        if (withHours){
            str =  day + '.' + month + '.' + date.getFullYear() + ' в ' + hour + ":" + min;
        } else {
            str =  day + '.' + month + '.' + date.getFullYear();
        }


        return str;
    }

    export function openUrlInNewTab(url) {
        window.open(url, '_blank').focus();
    }

    export function gid(name){
        if (document.querySelectorAll('#' + name).length !== 0){
            return $$.getElementById(name);
        } else {
            return null;
        }
    }

    export function getVal(id){
        if (document.querySelectorAll('#' + id).length !== 0){
            return document.getElementById(id).value;
        } else {
            return null;
        }
    }

    export function getDayPart(){
        let d = new Date();
        let h = d.getHours();

        let r;

        if (h < 12 && h > 6){
            r = 'Доброе утро';
        } else if (h >= 12 && h < 18) {
            r = 'Добрый день';
        } else if (h >= 18 && h < 21) {
            r = 'Добрый вечер';
        } else {
            r = 'Доброй ночи';
        }

        return r;

    }

import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {promiseAddBalance, promiseBalanceGet, getUserData} from "../../service/user";
import {openUrlInNewTab, setTitle, PROD, getVal} from "../../service/ui";

const Index = () => {
    const defaultValue = [];
    const [wallet, setWallet] = useState(defaultValue);

    let balanceID = useParams().wallet;

    useEffect(() => {
        promiseBalanceGet(balanceID).then(
            result => {
                let walletData = result.content;

                setWallet(walletData);
            },
            error => {
                alert(error);
            }
        );

    }, [balanceID]);

    // ===== [ONLOAD] =====

    let _name = (Boolean(wallet.isDefault)) ? 'Общий счёт' : wallet.name;
    setTitle(PROD + ' - Пополнить ' + _name);

    if (Number(wallet.userID) === Number(getUserData().id)) {
        return (
            <>
                <div className='scene-wrap gap-30 fade-in-bottom'>
                    <div className='com-col-left gap-10'>
                        <h1 className='base-heading'>Пополнить {_name}</h1>
                        <p className='base-desc'>{balanceID}</p>
                    </div>
                    <div className='com-col-left gap-15'>
                        <input id = 'AMOUNT' autoFocus='true' className='field' placeholder="Сумма пополнения" type="text"/>
                        <p className='base-desc'>Значение должно быть целым, отличным от 0, неотрицательным числом<br/>Сервис приёма платежей берёт комиссию - 4%<br/>Минимальная сумма пополнения 50 ₽</p>
                        <button className='btn main-btn' onClick={
                            () => {
                                promiseAddBalance(wallet.id, getVal('AMOUNT')).then(r => {
                                    if (r.code === 200) {
                                        openUrlInNewTab(r.content.payform);
                                    }
                                });
                            }
                        }><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="1.5" d="M12 20V4m-7 7l7-7l7 7"/>
                        </svg>
                            Пополнить
                        </button>
                    </div>
                </div>
            </>
        );
    } else {

    }
};

export default Index;

import './App.css';
import './static/css/style.css'

console.warn = console.error = () => {};
function App() {
  return (
    <>
    </>
  );
}
export default App;

import React from 'react';
import * as NN from "../../../service/NODENET-JS/nodenet";
import {gid} from "../../../service/ui";
import {formatBytes} from "../../../service/NODENET-JS/nodenet";
const DwnTask = ({taskinfo}) => {

    let interval = setInterval(() => {
        if (window.location.pathname !== '/d/io-operations'){
            clearInterval(interval);
        } else {
            taskinfo = NN.getTaskState(Number(taskinfo.id));

            gid('DWN_TASK_PERC_' + taskinfo.id).textContent = taskinfo.percentage + '%';
            gid('DWN_TASK_' + taskinfo.id).textContent = taskinfo.fragmentsUploaded + ' из ' + taskinfo.fragmentsCount;
            gid('DWN_TASK_SIZE_' + taskinfo.id).textContent = formatBytes(taskinfo.fragmentsUploadedInBytes) + ' из ' + formatBytes(taskinfo.filesize);

            let __state = (taskinfo.direction === 0) ?
                `<h4>Состояние <b class='state-ok'>Загрузка фрагментов</b></h4>`
                :
                `<h4>Состояние <b class='state-ok'>Скачивание фрагментов</b></h4>`;

            gid('DWN_TASK_STATE_' + taskinfo.id).replaceChildren();
            gid('DWN_TASK_STATE_' + taskinfo.id).insertAdjacentHTML('beforeend', __state);

            if (taskinfo.state === 2){
                gid('DWN_TASK_STATE_' + taskinfo.id).replaceChildren();

                gid('DWN_TASK_STATE_' + taskinfo.id).insertAdjacentHTML('beforeend', (taskinfo.direction === 1) ? `<h4>Состояние <b class='state-ok'>Скачивание завершено успешно</b></h4>` : `<h4>Состояние <b class='state-ok'>Загрузка завершена успешно</b></h4>`);

                clearInterval(interval);
            }
        }
    }, 1500);

    return (
        <>
            <div className='dwntask'>
                <div className='dwntask-group gap-20'>
                    <h3 id = {'DWN_TASK_PERC_' + taskinfo.id}>
                        -
                    </h3>
                    <div>
                        <h1>{taskinfo.filename.slice(0, 32) + '...'}</h1>
                        {
                            <div id = {'DWN_TASK_STATE_' + taskinfo.id}></div>
                        }
                    </div>
                </div>
                <div className='dwntask-group gap-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                        <g fill="none" stroke="currentColor" stroke-width="1.5">
                            <circle cx="4" cy="4" r="1" transform="rotate(90 4 4)"/>
                            <circle cx="12" cy="4" r="1" transform="rotate(90 12 4)"/>
                            <circle cx="20" cy="4" r="1" transform="rotate(90 20 4)"/>
                            <circle cx="4" cy="12" r="1" transform="rotate(90 4 12)"/>
                            <circle cx="12" cy="12" r="1" transform="rotate(90 12 12)"/>
                            <circle cx="20" cy="12" r="1" transform="rotate(90 20 12)"/>
                            <circle cx="4" cy="20" r="1" transform="rotate(90 4 20)"/>
                            <circle cx="12" cy="20" r="1" transform="rotate(90 12 20)"/>
                            <circle cx="20" cy="20" r="1" transform="rotate(90 20 20)"/>
                        </g>
                    </svg>
                    <p id = {'DWN_TASK_' + taskinfo.id}>{taskinfo.fragmentsUploaded} из {taskinfo.fragmentsCount}</p>
                </div>
                <div className='dwntask-group gap-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                        <g fill="none" stroke="currentColor" stroke-width="1.5">
                            <circle cx="12" cy="12" r="10"/>
                            <path stroke-linecap="round" d="M12 7h.01"/>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 11h2v5m-2 0h4"/>
                        </g>
                    </svg>
                    <p id = {'DWN_TASK_SIZE_' + taskinfo.id}>{formatBytes(taskinfo.fragmentsUploadedInBytes)} из {formatBytes(taskinfo.filesize)}</p>
                </div>
                <div className='dwntask-group gap-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                        <g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5">
                            <path stroke-linecap="round"
                                  d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.749l-7 3.888a2 2 0 0 1-1.942 0l-7-3.889A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874z"/>
                            <path d="m3 7l9 5m0 0l9-5m-9 5v10"/>
                            <path stroke-linecap="round" d="m7.5 9.5l9-5M6 12.328L9 14"/>
                        </g>
                    </svg>
                    <p>{taskinfo.fileext.toUpperCase()}-Файл</p>
                </div>
            </div>
        </>
    );
};

export default DwnTask;

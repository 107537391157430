
    import * as http from "./httpClient";

    const AVATARS_STORAGE = 'https://id.darknext.net/storage/users-avatars/';

    // ======= EXPORT'S BLOCK =======

    export let user;

    user = {};

    // ==== [PROMISES START] ====

    export function updateUserAvatar(file) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('user') !== null) {
                sendReq([
                    {'key': 'act', 'value': 'update-user-avatar'},
                    {'key': 'userID', 'value': user.id},
                    {'key': 'avatar', 'value': file},
                ], 1).then(res => {
                    if (res.code === 200){
                        user.avatar = AVATARS_STORAGE + res.content + '.jpg';
                    }

                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            } else {
                reject({'code': 205});
            }
        });
    }

    export let promiseUserCheck = new Promise((resolve, reject) => {
        if (localStorage.getItem('user') !== null){
            sendReq([
                {'key': 'act', 'value': 'get-user'},
                {'key': 'token', 'value': localStorage.getItem('user')}
            ]).then(res => {
                if (res.code !== 200){
                    localStorage.removeItem('user');
                } else {
                    user = res.content;

                    if (user.avatar !== null){
                        user.avatar = AVATARS_STORAGE + user.avatar + '.jpg';
                    }
                }

                resolve(res);
            }).catch(err => {
                localStorage.removeItem('user');

                reject(err);
            });
        } else {
            reject({'code': 205});
        }
    });

    export function promiseBalanceGet(walletID) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('user') !== null) {
                sendReq(
                    [
                        {'key': 'act', 'value': 'get-balance'},
                        {'key': 'balanceID', 'value': walletID}
                    ]
                ).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            } else {
                reject({'code': 205});
            }
        });
    }

    export function promiseActivateAccount(code) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('user') !== null) {
                sendReq(
                    [
                        {'key': 'act', 'value': 'prove-reg'},
                        {'key': 'code', 'value': code}
                    ]
                ).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            } else {
                reject({'code': 205});
            }
        });
    }

    export function promiseAddBalance(walletID, amount) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('user') !== null) {
                sendReq(
                    [
                        {'key': 'act', 'value': 'create-payment'},
                        {'key': 'balanceID', 'value': walletID},
                        {'key': 'userID', 'value': user.id},
                        {'key': 'amount', 'value': amount},
                    ]
                ).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            } else {
                reject({'code': 205});
            }
        });
    }

    export function promiseServicesGet() {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('user') !== null) {
                sendReq(
                    [
                        {'key': 'act', 'value': 'get-services'},
                    ]
                ).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            } else {
                reject({'code': 205});
            }
        });
    }

    export function promiseAuthorizeUser(login, password) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('user') === null) {
                sendReq(
                    [
                        {'key': 'act', 'value': 'login'},
                        {'key': 'login', 'value': login},
                        {'key': 'pass', 'value': password},
                    ]
                ).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            } else {
                reject({'code': 205});
            }
        });
    }

    // ==== [PROMISES END] ====

    export function getUserData(){
        return user;
    }

    function sendReq(paramsList, dataCompilerMode = 0){
        return new Promise((result, reject) => {
            let data = (dataCompilerMode === 0) ? new URLSearchParams() : new FormData();

            paramsList.forEach(item => {
                data.append(item.key, item.value);
            });

            httpPromise(data, result, reject);
        });
    }

    function httpPromise(data, result, reject){
        http.send({
            "method": 'post',
            "body": data
        }).then(r => {
            result(r);
        }).catch(err => {
            reject(err);
        });
    }
import React from 'react';
import {getFormattedDate, gid} from "../../../service/ui";
import {formatBytes} from "../../../service/NODENET-JS/nodenet";
import * as https from "../../../service/httpClient";
import * as NN from "../../../service/NODENET-JS/nodenet";

const Index = ({fileinfo}) => {
    let formatsList = [
        {'class': 'doc', 'formats': [], 'classCSS': 'pfile'},
        {'class': 'audio', 'formats': ['wav', 'mp3'], 'classCSS': 'paudio'},
        {'class': 'video', 'formats': ['mp4', 'avi'], 'classCSS': 'pvid'},
        {'class': 'img', 'formats': ['png', 'jpg'], 'classCSS': 'pimg'},
    ];

    let _fileType = 'pfile';
    let fileext = fileinfo.file.filename.split('.').at(-1).toLowerCase();

    formatsList.forEach(el => {
        if (el.formats.includes(fileext)){
            _fileType = el.classCSS;
        }
    });

    return (
        <>
            <div className='filedata' id={'FILE_' + fileinfo.file.id} key={fileinfo.file.id} onClick={() => {
                selectFile('FILE_' + fileinfo.file.id, fileinfo);
            }}>
                <div className='file-prev no-prev'>
                    {(fileinfo.file.instuctionID === null) ?
                        <svg className= 'err-svg' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                            <path fill="currentColor" fill-rule="evenodd"
                                  d="M14.543 2.598a2.821 2.821 0 0 0-5.086 0L1.341 18.563C.37 20.469 1.597 23 3.883 23h16.234c2.286 0 3.511-2.53 2.542-4.437zM12 8a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1m0 8.5a1 1 0 0 1 1 1v.5a1 1 0 1 1-2 0v-.5a1 1 0 0 1 1-1"
                                  clip-rule="evenodd"/>
                        </svg>
                        : <p className={_fileType}>{fileinfo.file.filename.split('.').at(-1).toUpperCase()}</p>}
                </div>
                <div className='fileinfo gap-5'>
                    <h1>{(fileinfo.file.filename.length <= 18) ? fileinfo.file.filename : fileinfo.file.filename.slice(0, 18) + '...'}</h1>
                    {
                        (fileinfo.file.instuctionID === null)
                            ? <h3>Попытка загрузки {getFormattedDate(fileinfo.file.date_create, false)}<br/>Сбой загрузки</h3>
                            : <h3>Загружено {getFormattedDate(fileinfo.file.date_create, false)}<br/>Размер: {formatBytes(fileinfo.in.fullsize)}</h3>
                    }
                </div>
            </div>
        </>
    );
};

export default Index;

/* for video-type */
/* <video loop autobuffer='true' muted width='200px' preload='auto'
                                   id={'FILE_VIDEO_' + fileinfo.file.id} onClick={(event) => {
                                fsd(event, fileinfo.in.id)
                            }} controls='true'></video> */

let selectedFiles = 0;
function selectFile(key, fileinfo) {
    gid(key).classList.add('filedata-sel');

    let body = new FormData();
    body.append('act', 'get-instruct');
    body.append('instruct_id', fileinfo.file.instuctionID);

    https.send(
        {
            'method': 'POST',
            'body': body
        }
    ).
    then(res => {
        if (res.code === 200) {
            NN.downloadFile(fileinfo.file.id, res.content, function (res)  {
                console.log(res)
            });
        } else {

        }
    });
}

function fsd(el, fileinfo){
    getter(fileinfo).
    then(res => {
        __cards(el, res.content.fragments);
    })
}

function __cards(el, cards){
    let __sdx = '';
    let max = cards.length;
    let get = 0;

    let sources = new Array(max);

    cards.forEach(e => {
        fileGetter(e.id).then(res => res.json()).
            then(res => {

            sources[get] = 'https://darknext.net/nodenet-proxy/get?address=https://api.telegram.org/file/bot5695758233:AAGi61Xczt5wTtpSx647gQlcsh1RL37PnNA/' + res.result.file_path

            get++;

            if (get === max) {
                console.log(sources)

                let __renderPart = 0;
                let blob = new Array(max);

                function loadPart() {
                    if (__renderPart < 4) {
                        fetch(sources[__renderPart], {
                            'method': 'GET',
                            'dataType': 'binary'
                        }).then(res => res.blob())
                            .then(blobx => {
                                blob[__renderPart] = blobx;

                                __renderPart++;

                                if (__renderPart < 4) {
                                    loadPart();
                                } else {
                                    let blobReady = [];

                                    blob.forEach(b => {
                                        blobReady.push(b);
                                    })

                                    display(new Blob(blobReady), document.getElementById(el.target.id));

                                    //document.getElementById(el.target.id).src = URL.createObjectURL(new Blob(blob));
                                    //document.getElementById(el.target.id).load();

                                }
                            })
                    } else {
                        //display(document.getElementById(el.target.id), new Blob(blob));
                    }
                }

                loadPart();
            }
        });
    });
}

function display( videoFile, videoEl ) {
    // Preconditions:
    if( !( videoFile instanceof Blob ) ) throw new Error( '`videoFile` must be a Blob or File object.' ); // The `File` prototype extends the `Blob` prototype, so `instanceof Blob` works for both.
    if( !( videoEl instanceof HTMLVideoElement ) ) throw new Error( '`videoEl` must be a <video> element.' );
    //
    const newObjectUrl = URL.createObjectURL( videoFile );
    // URLs created by `URL.createObjectURL` always use the `blob:` URI scheme: https://w3c.github.io/FileAPI/#dfn-createObjectURL
    const oldObjectUrl = videoEl.currentSrc;
    if( oldObjectUrl && oldObjectUrl.startsWith('blob:') ) {
        // It is very important to revoke the previous ObjectURL to prevent memory leaks. Un-set the `src` first.
        // See https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
        videoEl.src = ''; // <-- Un-set the src property *before* revoking the object URL.
        URL.revokeObjectURL( oldObjectUrl );
    }
    // Then set the new URL:
    videoEl.src = newObjectUrl;
    // And load it:
    videoEl.load(); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/load
    videoEl.play();
}

function fileGetter(__address){
    let fd = new FormData();
    fd.append('file_id', __address);

    return fetch('https://api.telegram.org/bot5695758233:AAGi61Xczt5wTtpSx647gQlcsh1RL37PnNA/getFile', {
        'method': 'POST',
        'body': fd,
        'dataType': 'binary'
    })
}

function getInstruction(instructID){

}

function getter(instructID){
    let body = new FormData();
    body.append('act', 'get-instruct');
    body.append('instruct_id', instructID);

    return https.send(
        {
            'method': 'POST',
            'body': body
        }
    );

}

// <img src='https://api.nodenet.ru/frame1.png' className='file-prev' alt='PNG'/>

import React from 'react';
import UserMain from "../../Components/User-main";
import UserSettings from "../../Components/User-settings";
import { Link } from "react-router-dom";
import Avatar from "./Form-components/avatar";
import UserProfile from "../../Components/User-profile";
import UserServices from "../../Components/User-services";
import UserWalletReplenish from "../../Components/User-wallet-replenish";
import UserStorages from "../../Components/User-storages";
import UserStoragesCreate from "../../Components/User-storages-create";
import UserDownloads from "../../Components/User-downloads";

const Index = ({page}) => {
    return (
        <>
            <div className='user-profile scale-in'>
                <div className='user-prof-wrap'>
                    <div className='left-prof'>
                        <div className='lfd gap-25'>
                            <Link to={'/d/main'}><div className={'left-smd gap-15 ' + ((page === 'main') ? 'smd-sel' : '')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path fill="none" stroke="currentColor" stroke-linecap="round"
                                          stroke-linejoin="round" stroke-width="1.5"
                                          d="M21 19v-6.733a4 4 0 0 0-1.245-2.9L13.378 3.31a2 2 0 0 0-2.755 0L4.245 9.367A4 4 0 0 0 3 12.267V19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2"/>
                                </svg>
                            </div>
                            </Link>
                            <Link to={'/d/files'}><div className={'left-smd gap-15 ' + ((page === 'files') ? 'smd-sel' : '')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <g fill="none" stroke="currentColor" stroke-width="1.5">
                                        <circle cx="4" cy="4" r="1" transform="rotate(90 4 4)"/>
                                        <circle cx="12" cy="4" r="1" transform="rotate(90 12 4)"/>
                                        <circle cx="20" cy="4" r="1" transform="rotate(90 20 4)"/>
                                        <circle cx="4" cy="12" r="1" transform="rotate(90 4 12)"/>
                                        <circle cx="12" cy="12" r="1" transform="rotate(90 12 12)"/>
                                        <circle cx="20" cy="12" r="1" transform="rotate(90 20 12)"/>
                                        <circle cx="4" cy="20" r="1" transform="rotate(90 4 20)"/>
                                        <circle cx="12" cy="20" r="1" transform="rotate(90 12 20)"/>
                                        <circle cx="20" cy="20" r="1" transform="rotate(90 20 20)"/>
                                    </g>
                                </svg>
                            </div>
                            </Link>
                            <Link to={'/d/io-operations'}>
                                <div className={'left-smd gap-15 ' + ((page === 'io') ? 'smd-sel' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <path fill="none" stroke="currentColor" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="1.5"
                                              d="M6 3v18M6 3l4 4M6 3L2 7m16 14V3m0 18l4-4m-4 4l-4-4"/>
                                    </svg>
                                    <h1 className='counter' id = 'IO_COUNTER' style = {{'display': 'none'}}></h1>
                                </div>
                            </Link>
                            <Link to={'/d/storages'}>
                                <div className={'left-smd gap-15 ' + ((page === 'storages') ? 'smd-sel' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <g fill="none" stroke="currentColor" stroke-linecap="round"
                                           stroke-linejoin="round" stroke-width="1.5">
                                            <ellipse cx="12" cy="6" rx="8" ry="3"/>
                                            <path
                                                d="M6.037 12C4.77 12.53 4 13.232 4 14c0 1.657 3.582 3 8 3s8-1.343 8-3c0-.768-.77-1.47-2.037-2"/>
                                            <path
                                                d="M4 6v4c0 1.657 3.582 3 8 3s8-1.343 8-3V6M4 14v4c0 1.657 3.582 3 8 3s8-1.343 8-3v-4"/>
                                        </g>
                                    </svg>
                                    <h1 className='counter'></h1>
                                </div>
                            </Link>
                            <Link to={'/d/settings'}>
                                <div className={'left-smd gap-15 ' + ((page === 'settings') ? 'smd-sel' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <g fill="none" stroke="currentColor" stroke-width="1.5">
                                        <path
                                            d="M14 3.269C14 2.568 13.432 2 12.731 2H11.27C10.568 2 10 2.568 10 3.269c0 .578-.396 1.074-.935 1.286c-.085.034-.17.07-.253.106c-.531.23-1.162.16-1.572-.249a1.269 1.269 0 0 0-1.794 0L4.412 5.446a1.269 1.269 0 0 0 0 1.794c.41.41.48 1.04.248 1.572a7.946 7.946 0 0 0-.105.253c-.212.539-.708.935-1.286.935C2.568 10 2 10.568 2 11.269v1.462C2 13.432 2.568 14 3.269 14c.578 0 1.074.396 1.286.935c.034.085.07.17.105.253c.231.531.161 1.162-.248 1.572a1.269 1.269 0 0 0 0 1.794l1.034 1.034a1.269 1.269 0 0 0 1.794 0c.41-.41 1.04-.48 1.572-.249c.083.037.168.072.253.106c.539.212.935.708.935 1.286c0 .701.568 1.269 1.269 1.269h1.462c.701 0 1.269-.568 1.269-1.269c0-.578.396-1.074.935-1.287c.085-.033.17-.068.253-.104c.531-.232 1.162-.161 1.571.248a1.269 1.269 0 0 0 1.795 0l1.034-1.034a1.269 1.269 0 0 0 0-1.794c-.41-.41-.48-1.04-.249-1.572c.037-.083.072-.168.106-.253c.212-.539.708-.935 1.286-.935c.701 0 1.269-.568 1.269-1.269V11.27c0-.701-.568-1.269-1.269-1.269c-.578 0-1.074-.396-1.287-.935a7.755 7.755 0 0 0-.105-.253c-.23-.531-.16-1.162.249-1.572a1.269 1.269 0 0 0 0-1.794l-1.034-1.034a1.269 1.269 0 0 0-1.794 0c-.41.41-1.04.48-1.572.249a7.913 7.913 0 0 0-.253-.106C14.396 4.343 14 3.847 14 3.27z"/>
                                        <path d="M16 12a4 4 0 1 1-8 0a4 4 0 0 1 8 0Z"/>
                                    </g>
                                </svg>
                            </div>
                            </Link>
                        </div>
                        <Link to={'/d/profile'}><div className='user'>
                            <Avatar type='min'/>
                        </div></Link>
                    </div>
                    <div className='scene' id='SCENE'>
                        {renderContent(page)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;

function renderContent(_pageName){
    switch (_pageName){
        case 'main':
            return <UserMain/>;

        case 'settings':
            return <UserSettings/>;

        case 'io':
            return <UserDownloads/>;

        case 'profile':
            return <UserProfile/>;

        case 'files':
            return <UserServices/>;

        case 'storages':
            return <UserStorages/>;

        case 'storages-create':
            return <UserStoragesCreate/>;

        case 'wallet-replenish':
            return <UserWalletReplenish/>;

        default:
            break;
    }
}